import { useState, useEffect } from "react";
import { Row, Col } from "antd";
import ImagenAnimada from "../imgs/ImagenAnimada.svg";
import GraficaSide01 from "../imgs/GraficaSide01.svg";
import GraficaSide02 from "../imgs/GraficaSide02.svg";
import Manga01 from "../imgs/Manga01-01.svg";
import Manga02 from "../imgs/Manga02-01.svg";
import Manga03 from "../imgs/Manga03-01.svg";
import Manga04 from "../imgs/Manga04-01.svg";
import ArrowLeft from "../imgs/BotonIZQ.svg";
import ArrowRight from "../imgs/BotonDER.svg";
import "./download.css";
import side01 from "../anims/Grafica 04 separada.json";
import side02 from "../anims/Grafica 04 separada 2.json";
import bigBg from "../imgs/bigBgDownload.jpg";
import Lottie from "lottie-react";
import brushBack from "../imgs/BrushBack-01.svg";
import espVer01 from "../pdfs/Capitulo01 - ESP.pdf";
import engVer01 from "../pdfs/Episode01- ENG.pdf";

const Download = ({ setNavbarMode, adminMode }) => {
  useEffect(() => {
    if (adminMode == "") {
      setNavbarMode("white");
    } else {
      setNavbarMode("black");
    }
  }, []);

  return (
    <>
      {
        (adminMode = adminMode ? (
          <div
            style={{
              position: "relative",
              height: "90vh",
              overflow: "hidden",
            }}
          >
            {" "}
            <div className="mask-download-black"></div>
            <div className="download-bg-black">
              <div className="download-black"></div>
            </div>
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "0%",
                width: "100%",
                zIndex: 3,
              }}
            >
              <Row justify="center" align="middle">
                <Col>
                  <button
                    style={{
                      background: "red",
                      border: "none",
                      borderRadius: "1em",
                      paddingLeft: "2em",
                      paddingRight: "2em",
                      margin: "1em",
                      cursor: "pointer",
                    }}
                  >
                    <a href={engVer01} download="Episode01-ENG">
                      <h1 style={{ color: "white" }}>Download</h1>
                    </a>
                  </button>
                </Col>
                <Col>
                  <button
                    style={{
                      background: "red",
                      border: "none",
                      borderRadius: "1em",
                      paddingLeft: "2em",
                      paddingRight: "2em",
                      cursor: "pointer",
                    }}
                  >
                    <a href={espVer01} download="Capitulo01-ESP">
                      {" "}
                      <h1 style={{ color: "white" }}>Descargar</h1>
                    </a>
                  </button>
                </Col>
              </Row>
            </div>
          </div>
        ) : (
          <div
            style={{
              position: "relative",
              height: "90vh",
              overflow: "hidden",
            }}
          >
            <div className="download-bg">
              {" "}
              <div className="download"></div>
            </div>
            <div
              style={{
                position: "absolute",
                top: "15%",
                left: "5%",
                width: "100%",
              }}
            >
              <Row justify="space-between" align="middle">
                <Col span={2}>
                  <Lottie animationData={side01} height="400em" />
                </Col>
                <Col span={2}>
                  <img src={Manga01} alt="" height="400em" />
                </Col>
                <Col span={2}>
                  <img src={Manga02} alt="" height="400em" />
                </Col>
                <Col span={2}>
                  <img src={Manga03} alt="" height="400em" />
                </Col>
                <Col span={2}>
                  <img src={Manga04} alt="" height="400em" />
                </Col>
                <Col span={2}>
                  <Lottie animationData={side02} height="400em" />
                </Col>
                <Col span={8}>
                  <img src={ImagenAnimada} alt="" height="400em" />
                </Col>
              </Row>
            </div>
          </div>
        ))
      }
    </>
  );
};

export default Download;
