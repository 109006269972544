import "./App.css";
import { Routes, Route } from "react-router-dom";
import { useState } from "react";
import CountdownPage from "./components/CountdownPage";
import MainPage from "./components/MainPage";
import Information from "./components/Information";
import Navbar from "./components/Navbar";
import MoreInformation from "./components/MoreInformation";
import Characters from "./components/Characters";
import Download from "./components/Download";

function App() {
  const [navbarMode, setNavbarMode] = useState("black");
  const [admin, setAdmin] = useState("admin");
  const [lang, setLang] = useState("esp");
  return (
    <div className="App">
      <Navbar
        mode={navbarMode}
        adminMode={admin}
        setLang={setLang}
        lang={lang}
      />
      <Routes>
        <Route
          path="/"
          element={<MainPage setNavbarMode={setNavbarMode} lang={lang} />}
        />
        <Route
          path="/information"
          element={<Information setNavbarMode={setNavbarMode} lang={lang} />}
        />
        <Route
          path="/more-information"
          element={
            <MoreInformation setNavbarMode={setNavbarMode} lang={lang} />
          }
        />
        <Route
          path="/characters"
          element={<Characters setNavbarMode={setNavbarMode} lang={lang} />}
        />
        <Route
          path="/download"
          element={
            <Download
              setNavbarMode={setNavbarMode}
              adminMode={admin}
              lang={lang}
            />
          }
        />
      </Routes>
    </div>
  );
}

export default App;
