import { useState, useEffect } from "react";
import { Row, Col } from "antd";
import { Link } from "react-router-dom";
import logo from "../imgs/LOGOV2.svg";
import logoBlack from "../imgs/LOGOV2Black.svg";
import "./navbar.css";
import discord from "../imgs/Discord-01.svg";
import twitter from "../imgs/Twitter.svg";
import instragram from "../imgs/Instagram.svg";
import youtube from "../imgs/Youtube.svg";
import discordBlack from "../imgs/DiscordBlack-01.svg";
import twitterBlack from "../imgs/Twitter-black.svg";
import instragramBlack from "../imgs/Instagram-black.svg";
import youtubeBlack from "../imgs/Youtube-black.svg";

const Navbar = ({ mode, adminMode, lang, setLang }) => {
  const [justifyOption, setJustifyOption] = useState("space-between");
  const [fontSize, setfontSize] = useState("");
  const [fontSizeWhite, setFontSizeWhite] = useState("");

  useEffect(() => {
    checkWidth();
  }, []);

  function language(e) {
    e.preventDefault();
    if (lang == "esp") {
      setLang("eng");
    } else {
      setLang("esp");
    }
    console.log(lang);
  }

  function checkWidth() {
    if (window.outerWidth > 1000) {
      setJustifyOption("space-between");
      setfontSize("btn-navbar");
      setFontSizeWhite("btn-navbar-white");
    } else if (window.outerWidth > 425 && window.outerWidth < 1000) {
      setJustifyOption("center");
      setfontSize("btn-navbar-mobile-t");
      setFontSizeWhite("btn-navbar-white-mobile-t");
    } else if (window.outerWidth <= 425) {
      setJustifyOption("center");
      setfontSize("btn-navbar-mobile");
      setFontSizeWhite("btn-navbar-white-mobile");
    }
  }

  window.addEventListener("resize", function () {
    checkWidth();
  });

  window.addEventListener("orientationchange", function () {
    checkWidth();
  });

  return (
    <>
      {adminMode == "" ? (
        <div></div>
      ) : (
        <div style={{ backgroundColor: mode }}>
          <Row align={"middle"} justify={justifyOption}>
            <Col>
              <img
                src={mode == "black" ? logo : logoBlack}
                alt=""
                width="150em"
                style={{ padding: "1em" }}
              />
            </Col>
            <Col>
              <Link to="/">
                <button className={mode == "black" ? fontSize : fontSizeWhite}>
                  {lang == "esp" ? "INICIO" : "HOME"}
                </button>
              </Link>
              <span style={{ color: mode == "black" ? "white" : "black" }}>
                |
              </span>
              <Link to="/information">
                <button className={mode == "black" ? fontSize : fontSizeWhite}>
                  {lang == "esp" ? "INFORMACION" : "INFORMATION"}
                </button>
              </Link>
              <span style={{ color: mode == "black" ? "white" : "black" }}>
                |
              </span>
              <Link to="/characters">
                <button className={mode == "black" ? fontSize : fontSizeWhite}>
                  {lang == "esp" ? "PERSONAJES" : "CHARACTERS"}
                </button>
              </Link>
              <span style={{ color: mode == "black" ? "white" : "black" }}>
                |
              </span>
              <Link to="/download">
                <button className={mode == "black" ? fontSize : fontSizeWhite}>
                  {lang == "esp" ? "DESCARGAR" : "DOWNLOAD"}
                </button>
              </Link>
            </Col>
            <Col>
              <Row justify={"center"} align={"middle"}>
                {" "}
                <button
                  type="button"
                  className={mode == "black" ? fontSize : fontSizeWhite}
                  onClick={(e) => language(e)}
                >
                  {lang == "esp" ? <p>ESPAÑOL</p> : <p>ENGLISH</p>}
                </button>
                <span className="social-col">
                  <a href="www.instagram.com/dgastudios">
                    {" "}
                    <img
                      src={mode == "black" ? instragram : instragramBlack}
                      alt=""
                      style={{ width: "1.2em" }}
                    />
                  </a>
                </span>
                <span className="social-col">
                  <a href="www.youtube.com/channel/UCdEcPo5ntK6Hp0TBTcwr5rg">
                    {" "}
                    <img
                      src={mode == "black" ? youtube : youtubeBlack}
                      alt=""
                      style={{ width: "1.2em" }}
                    />
                  </a>
                </span>
                <span className="social-col">
                  <a href="https://twitter.com/Pixel_Index">
                    {" "}
                    <img
                      src={mode == "black" ? twitter : twitterBlack}
                      alt=""
                      style={{ width: "1.2em" }}
                    />
                  </a>
                </span>
                <span className="social-col">
                  <a href="https://discord.com/channels/1084175735788421160/1084175737235460228">
                    {" "}
                    <img
                      src={mode == "black" ? discord : discordBlack}
                      alt=""
                      style={{ width: "1.2em" }}
                    />
                  </a>
                </span>
              </Row>
            </Col>
          </Row>
        </div>
      )}
      <div style={{ width: "0", height: "0", opacity: "0" }}>
        <img src={logo} style={{ width: "0", opacity: "0" }} />
        <img src={logoBlack} style={{ width: "0", opacity: "0" }} />
        <img src={discord} style={{ width: "0", opacity: "0" }} />
        <img src={discordBlack} style={{ width: "0", opacity: "0" }} />
        <img src={twitter} style={{ width: "0", opacity: "0" }} />
        <img src={twitterBlack} style={{ width: "0", opacity: "0" }} />
        <img src={instragram} style={{ width: "0", opacity: "0" }} />
        <img src={instragramBlack} style={{ width: "0", opacity: "0" }} />
        <img src={youtube} style={{ width: "0", opacity: "0" }} />
        <img src={youtubeBlack} style={{ width: "0", opacity: "0" }} />
      </div>
    </>
  );
};

export default Navbar;
