import { useState, useEffect, useRef } from "react";
import { Row, Col } from "antd";
import "./characters.css";
import brushBack from "../imgs/BrushBack-01.svg";
import Santiago from "../imgs/Avatar Santiago 01.png";
import Topo from "../imgs/Avatar Topo 02.png";
import Hannah from "../imgs/Avatar Hannah 03.png";
import Anton from "../imgs/Avatar Anton 04.png";
import Artemisa from "../imgs/Avatar Artemisa 05.png";
import Logan from "../imgs/Avatar Logan 07.png";
import Lorein from "../imgs/Avatar Lorrein 06.png";
import Ernest from "../imgs/Avatar Ernest 08.png";
import SantiagoBg from "../imgs/01 Santiago.png";
import TopoBg from "../imgs/02 Christopher.png";
import HannahBg from "../imgs/03 Hannah.png";
import AntonBg from "../imgs/04 Anton.png";
import ArtemisaBg from "../imgs/05 Artemisa.png";
import LoganBg from "../imgs/07 Logan.png";
import LoreinBg from "../imgs/06 Lorrein.png";
import ErnestBg from "../imgs/08 Ernest.png";
import santiagoNick from "../anims/SANTIAGO WAZNER GRAFICA 01.json";
import santiagoSide from "../anims/SANTIAGO WAZNER SIDE.json";
import santiagoStats from "../anims/STATS 01.json";
import topoNick from "../anims/CHRIS MAYER GRAFICA 02.json";
import topoSide from "../anims/CHRISTOPHER MAYER SIDE.json";
import topoStats from "../anims/STATS 02.json";
import hannahNick from "../anims/HANNAH BROWN GRAFICA 03.json";
import hannahSide from "../anims/HANNAH BROWN SIDE.json";
import hannahStats from "../anims/STATS 03.json";
import antonNick from "../anims/ANTON GRANT GRAFICA 04.json";
import antonSide from "../anims/ANTON GRANT SIDE.json";
import antonStats from "../anims/STATS 04.json";
import artemisaNick from "../anims/ARTEMISA BROWN GRAFICA 05.json";
import artemisaSide from "../anims/ARTEMISA BROWN SIDE.json";
import artemisaStats from "../anims/STATS 05.json";
import ernestNick from "../anims/ERNETS ULSTER GRAFICA 06.json";
import ernestSide from "../anims/ERNEST ULSTER.json";
import ernestStats from "../anims/STATS 08.json";
import loreinNick from "../anims/LORREIN ULSTER GRAFICA 07.json";
import loreinSide from "../anims/LORREIN ULSTER.json";
import loreinStats from "../anims/STATS 06.json";
import loagnNick from "../anims/LOGAN NOLDS GRAFICA 08.json";
import loganSide from "../anims/LOGAN NOLDS SIDE.json";
import loganStats from "../anims/STATS 07.json";
import graficaBottom from "../anims/Grafica Down.json";
import loadingAnim from "../anims/Loading.json";
import borderCorner from "../imgs/border-01-white.svg";

import mobileTitleAnton from "../anims/ANTON GRANT GRAFICA 04_v2.json";
import mobileTitleArtemisa from "../anims/ARTEMISA BROWN GRAFICA 05_v2.json";
import mobileTitleTopo from "../anims/CHRIS MAYER GRAFICA 02_v2.json";
import mobileTitleErnest from "../anims/ERNETS ULSTER GRAFICA 06_v2.json";
import mobileTitleHannah from "../anims/HANNAH BROWN GRAFICA 03_v2.json";
import mobileTitleLogan from "../anims/LOGAN NOLDS GRAFICA 08_v2.json";
import mobileTitleLorrein from "../anims/LORREIN ULSTER GRAFICA 07_v2.json";
import mobileTitleSantiago from "../anims/SANTIAGO WAZNER GRAFICA 01_v2.json";

import Lottie from "lottie-react";

const Characters = ({ setNavbarMode, lang }) => {
  const [borderRadiusSize, setBorderRadiusSize] = useState("");
  const [sideNameSize, setSideNameSize] = useState("");
  const [justifyOption, setjustifyOption] = useState("");
  const [device, setDevice] = useState("");
  const [charTitleAnton, setCharTitleAnton] = useState("");
  const [charTitleArtemisa, setCharTitleArtemisa] = useState("");
  const [charTitleTopo, setCharTitleTopo] = useState("");
  const [charTitleErnest, setCharTitleErnest] = useState("");
  const [charTitleHannah, setCharTitleHannah] = useState("");
  const [charTitleLogan, setCharTitleLogan] = useState("");
  const [charTitleLorrein, setCharTitleLorrein] = useState("");
  const [charTitleSantiago, setCharTitleSantiago] = useState("");

  useEffect(() => {
    setNavbarMode("black");
    checkWidth();
  }, []);

  function checkWidth() {
    if (window.outerWidth > 1000) {
      setBorderRadiusSize("110%");
      setSideNameSize("10em");
      setCharTitleAnton(antonNick);
      setCharTitleArtemisa(artemisaNick);
      setCharTitleTopo(topoNick);
      setCharTitleErnest(ernestNick);
      setCharTitleHannah(hannahNick);
      setCharTitleLogan(loagnNick);
      setCharTitleLorrein(loreinNick);
      setCharTitleSantiago(santiagoNick);
      setDevice("pc");
    } else if (window.outerWidth > 425 && window.outerWidth < 1000) {
      setBorderRadiusSize("0");
      setSideNameSize("0");
      setCharTitleAnton(mobileTitleAnton);
      setCharTitleArtemisa(mobileTitleArtemisa);
      setCharTitleTopo(mobileTitleTopo);
      setCharTitleErnest(mobileTitleErnest);
      setCharTitleHannah(mobileTitleHannah);
      setCharTitleLogan(mobileTitleLogan);
      setCharTitleLorrein(mobileTitleLorrein);
      setCharTitleSantiago(mobileTitleSantiago);
      setDevice("tablet");
    } else if (window.outerWidth <= 425) {
      setBorderRadiusSize("0");
      setSideNameSize("0");
      setCharTitleAnton(mobileTitleAnton);
      setCharTitleArtemisa(mobileTitleArtemisa);
      setCharTitleTopo(mobileTitleTopo);
      setCharTitleErnest(mobileTitleErnest);
      setCharTitleHannah(mobileTitleHannah);
      setCharTitleLogan(mobileTitleLogan);
      setCharTitleLorrein(mobileTitleLorrein);
      setCharTitleSantiago(mobileTitleSantiago);
      setDevice("phone");
    }
  }

  window.addEventListener("resize", function () {
    checkWidth();
  });

  window.addEventListener("orientationchange", function () {
    checkWidth();
  });

  const [loading, setLoading] = useState(true);
  const [imagesLoaded, setImagesLoaded] = useState(0);
  const totalImages = useRef(0);

  useEffect(() => {
    const images = Array.from(document.querySelectorAll("img"));
    totalImages.current = images.length;
    console.log(images);
    const handleImageLoad = () => {
      setImagesLoaded((prevImagesLoaded) => prevImagesLoaded + 1);
    };

    images.forEach((image) => {
      if (image.complete) {
        handleImageLoad();
      } else {
        image.addEventListener("load", handleImageLoad);
      }
    });

    return () => {
      images.forEach((image) => {
        image.removeEventListener("load", handleImageLoad);
      });
    };
  }, []);

  useEffect(() => {
    if (imagesLoaded === totalImages.current) {
      setLoading(false);
    }
  }, [imagesLoaded]);

  const characters = [
    {
      thumb: Santiago,
      big: SantiagoBg,
      nick: charTitleSantiago,
      side: santiagoSide,
      stats: santiagoStats,
      text: {
        age: "16",
        psi: "Velocista, Elemento",
        inst: "Colegio Prix",
        ran: "Estudiante (None)",
        gym: "10",
      },
      textEng: {
        age: "16",
        psi: "Sprinters, Elements",
        inst: "Prix School",
        ran: "Student (None)",
        gym: "10",
      },
    },
    {
      thumb: Topo,
      big: TopoBg,
      nick: charTitleTopo,
      side: topoSide,
      stats: topoStats,
      text: {
        age: "16",
        psi: "Constructor",
        inst: "Colegio Prix",
        ran: "Estudiante (None)",
        gym: "10",
      },
      textEng: {
        age: "16",
        psi: "Builder",
        inst: "Prix School",
        ran: "Student (None)",
        gym: "10",
      },
    },
    {
      thumb: Hannah,
      big: HannahBg,
      nick: charTitleHannah,
      side: hannahSide,
      stats: hannahStats,
      text: {
        age: "16",
        psi: "Creador, Perfeccionista",
        inst: "Instituto Golden",
        ran: "Estudiante Lv.2 (2550)",
        gym: "10",
      },
      textEng: {
        age: "16",
        psi: "Creator, Perfectionist",
        inst: "Golden Institute",
        ran: "Estudiante Lv.2 (2550)",
        gym: "10",
      },
    },
    {
      thumb: Anton,
      big: AntonBg,
      nick: charTitleAnton,
      side: antonSide,
      stats: antonStats,
      text: {
        age: "39",
        psi: "Velocista",
        inst: "Desconocido",
        ran: "Wall (01) / Capitán",
        gym: "10",
      },
      textEng: {
        age: "39",
        psi: "Sprinters",
        inst: "Unknown",
        ran: "Wall (01) / Captain",
        gym: "10",
      },
    },
    {
      thumb: Artemisa,
      big: ArtemisaBg,
      nick: charTitleArtemisa,
      side: artemisaSide,
      stats: artemisaStats,
      text: {
        age: "26",
        psi: "Perfeccionista, Constructor",
        inst: "Golden Institute",
        ran: "Shield (13)",
        gym: "10",
      },
      textEng: {
        age: "26",
        psi: "Perfectionist, Builder",
        inst: "Golden Institute",
        ran: "Shield (13)",
        gym: "10",
      },
    },
    {
      thumb: Ernest,
      big: ErnestBg,
      nick: charTitleErnest,
      side: ernestSide,
      stats: ernestStats,
      text: {
        age: "18",
        psi: "Elemento",
        inst: "Golden Institute",
        ran: "Estudiante Lv.3 (350)",
        gym: "10",
      },
      textEng: {
        age: "18",
        psi: "Elements",
        inst: "Golden Institute",
        ran: "Student Lv.3 (350)",
        gym: "10",
      },
    },
    {
      thumb: Lorein,
      big: LoreinBg,
      nick: charTitleLorrein,
      side: loreinSide,
      stats: loreinStats,
      text: {
        age: "17",
        psi: "Creador, Constructor",
        inst: "Golden Institute",
        ran: "Estudiante (525)",
        gym: "10",
      },
      textEng: {
        age: "17",
        psi: "Creator, Builder",
        inst: "Golden Institute",
        ran: "Student (525)",
        gym: "10",
      },
    },
    {
      thumb: Logan,
      big: LoganBg,
      nick: charTitleLogan,
      side: loganSide,
      stats: loganStats,
      text: {
        age: "18",
        psi: "Elemento",
        inst: "Golden Institute",
        ran: "Estudiante Lv.3 (225)",
        gym: "10",
      },
      textEng: {
        age: "18",
        psi: "Elements",
        inst: "Golden Institute",
        ran: "Student Lv.3 (225)",
        gym: "10",
      },
    },
  ];

  const [selected, setSelected] = useState(0);

  return (
    <>
      {loading ? (
        <div className="loading-screen">
          <Lottie animationData={loadingAnim} className="loading" />
        </div>
      ) : device == "pc" ? (
        <div style={{ display: "flex", height: "100%" }}>
          <Row
            justify={"space-between"}
            wrap={false}
            style={{
              position: "relative",
              height: "90vh",
              overflow: "hidden",
              width: "100%",
            }}
          >
            <Col>
              <Lottie
                animationData={characters.at(selected).side}
                loop={false}
                style={{ width: sideNameSize }}
              />
            </Col>
            <Col style={{ paddingLeft: "2em" }}>
              <Row style={{ color: "white" }}>
                <Lottie
                  animationData={characters.at(selected).nick}
                  loop={false}
                  style={{ width: "50em" }}
                />
              </Row>
              <Row style={{ color: "white" }} align={"middle"}>
                <Col span={10}>
                  <div
                    style={{
                      position: "relative",
                      left: "0%",
                      padding: "1em",
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        bottom: "-200%",
                        left: "-0%",
                      }}
                    ></div>
                    {lang == "esp" ? (
                      <div
                        style={{
                          position: "absolute",
                          bottom: "-200%",
                          left: "0%",
                          textAlign: "left",
                          width: "20em",
                          fontSize: "1em",
                          fontFamily: "Orbitron, sans-serif",
                        }}
                      >
                        <span>{"Psique: ".toUpperCase()}</span>
                        <span>{characters.at(selected).text.psi}</span>
                        <p></p>
                        <span>{"Instituto: ".toUpperCase()}</span>
                        <span>{characters.at(selected).text.inst}</span>
                        <p></p>
                        <span>{"Ranking: ".toUpperCase()}</span>
                        <span>{characters.at(selected).text.ran}</span>
                        <p></p>
                        <span>{"Gimnasio: ".toUpperCase()}</span>
                        <span>{characters.at(selected).text.gym}</span>
                        <p></p>
                      </div>
                    ) : (
                      <div
                        style={{
                          position: "absolute",
                          bottom: "-200%",
                          left: "0%",
                          textAlign: "left",
                          width: "20em",
                          fontSize: "1em",
                          fontFamily: "Orbitron, sans-serif",
                        }}
                      >
                        <span>{"Psique: ".toUpperCase()}</span>
                        <span>{characters.at(selected).textEng.psi}</span>
                        <p></p>
                        <span>{"Institute: ".toUpperCase()}</span>
                        <span>{characters.at(selected).textEng.inst}</span>
                        <p></p>
                        <span>{"Ranking: ".toUpperCase()}</span>
                        <span>{characters.at(selected).textEng.ran}</span>
                        <p></p>
                        <span>{"Gym: ".toUpperCase()}</span>
                        <span>{characters.at(selected).textEng.gym}</span>
                        <p></p>
                      </div>
                    )}
                  </div>
                </Col>
                <Col>
                  <Lottie
                    animationData={characters.at(selected).stats}
                    loop={false}
                    style={{ width: "15em", paddingLeft: "3em" }}
                  />
                </Col>
              </Row>
              <Row style={{ color: "white", paddingTop: "1em" }}>
                {characters.map((item, index) => (
                  <img
                    src={item.thumb}
                    alt=""
                    width="10%"
                    height="90%"
                    style={{
                      border: index == selected ? "red solid 0.2em" : "none",
                      padding: "0.2em",
                      zIndex: 3,
                    }}
                    onClick={() => setSelected(index)}
                    id={index}
                  />
                ))}
                <img
                  src={characters.at(selected).big}
                  alt=""
                  height="120%"
                  style={{
                    position: "absolute",
                    top: "-10%",
                    left: "60%",
                    zIndex: 1,
                  }}
                />
              </Row>
              <Row style={{ color: "white" }}>
                <Lottie
                  animationData={graficaBottom}
                  style={{ width: "25em " }}
                />
              </Row>
            </Col>
            <Col>
              <img src={brushBack} alt="" height="100%" />
            </Col>
          </Row>
        </div>
      ) : device == "phone" ? (
        <div
          style={{
            position: "relative",
            overflow: "hidden",
          }}
        >
          <Row
            justify="space-evenly"
            wrap={false}
            style={{
              height: "100%",
              width: "100%",
            }}
          >
            <Col>
              <Row style={{ color: "white" }}>
                <Lottie
                  animationData={characters.at(selected).nick}
                  loop={false}
                  style={{ width: "50em" }}
                />
              </Row>
              <Row style={{ color: "white" }} align={"middle"}>
                <Col span={14}>
                  <Lottie
                    animationData={characters.at(selected).stats}
                    loop={false}
                    style={{ width: "10em" }}
                  />
                  {lang == "esp" ? (
                    <div
                      style={{
                        fontSize: "0.7em",
                        fontFamily: "Orbitron, sans-serif",
                      }}
                    >
                      <span>{"Psique: ".toUpperCase()}</span>
                      <span>{characters.at(selected).text.psi}</span>
                      <p></p>
                      <span>{"Instituto: ".toUpperCase()}</span>
                      <span>{characters.at(selected).text.inst}</span>
                      <p></p>
                      <span>{"Ranking: ".toUpperCase()}</span>
                      <span>{characters.at(selected).text.ran}</span>
                      <p></p>
                      <span>{"Gimnasio: ".toUpperCase()}</span>
                      <span>{characters.at(selected).text.gym}</span>
                      <p></p>
                    </div>
                  ) : (
                    <div
                      style={{
                        fontSize: "0.7em",
                        fontFamily: "Orbitron, sans-serif",
                      }}
                    >
                      <span>{"Psique: ".toUpperCase()}</span>
                      <span>{characters.at(selected).textEng.psi}</span>
                      <p></p>
                      <span>{"Institute: ".toUpperCase()}</span>
                      <span>{characters.at(selected).textEng.inst}</span>
                      <p></p>
                      <span>{"Ranking: ".toUpperCase()}</span>
                      <span>{characters.at(selected).textEng.ran}</span>
                      <p></p>
                      <span>{"Gym: ".toUpperCase()}</span>
                      <span>{characters.at(selected).textEng.gym}</span>
                      <p></p>
                    </div>
                  )}
                </Col>
              </Row>
              <Row style={{ color: "white" }}>
                {characters.map((item, index) => (
                  <img
                    src={item.thumb}
                    alt=""
                    width="20%"
                    height="90%"
                    style={{
                      border: index == selected ? "red solid 0.2em" : "none",
                      zIndex: 3,
                    }}
                    onClick={() => setSelected(index)}
                    id={index}
                  />
                ))}
                <img
                  src={characters.at(selected).big}
                  alt=""
                  height="100%"
                  style={{
                    position: "absolute",
                    bottom: "0%",
                    left: "18vw",
                    zIndex: 1,
                  }}
                />
              </Row>
              <Row style={{ color: "white" }}>
                <Lottie
                  animationData={graficaBottom}
                  style={{ width: "25em " }}
                />
              </Row>
            </Col>
          </Row>
        </div>
      ) : (
        <div>
          <Row
            justify="space-evenly"
            wrap={false}
            style={{
              height: "90vh",
              width: "100%",
              overflow: "hidden",
            }}
          >
            <Col style={{ paddingLeft: "2em" }}>
              <Row style={{ color: "white" }}>
                <Lottie
                  animationData={characters.at(selected).nick}
                  loop={false}
                  style={{ width: "50em" }}
                />
              </Row>
              <Row style={{ color: "white" }} align={"middle"}>
                <Col span={14}>
                  <Lottie
                    animationData={characters.at(selected).stats}
                    loop={false}
                    style={{ width: "15em" }}
                  />
                  {lang == "esp" ? (
                    <div
                      style={{
                        fontSize: "1em",
                        fontFamily: "Orbitron, sans-serif",
                        paddingTop: "2em",
                      }}
                    >
                      <span>{"Psique: ".toUpperCase()}</span>
                      <span>{characters.at(selected).text.psi}</span>
                      <p></p>
                      <span>{"Instituto: ".toUpperCase()}</span>
                      <span>{characters.at(selected).text.inst}</span>
                      <p></p>
                      <span>{"Ranking: ".toUpperCase()}</span>
                      <span>{characters.at(selected).text.ran}</span>
                      <p></p>
                      <span>{"Gimnasio: ".toUpperCase()}</span>
                      <span>{characters.at(selected).text.gym}</span>
                      <p></p>
                    </div>
                  ) : (
                    <div
                      style={{
                        fontSize: "1em",
                        fontFamily: "Orbitron, sans-serif",
                        paddingTop: "2em",
                      }}
                    >
                      <span>{"Psique: ".toUpperCase()}</span>
                      <span>{characters.at(selected).textEng.psi}</span>
                      <p></p>
                      <span>{"Institute: ".toUpperCase()}</span>
                      <span>{characters.at(selected).textEng.inst}</span>
                      <p></p>
                      <span>{"Ranking: ".toUpperCase()}</span>
                      <span>{characters.at(selected).textEng.ran}</span>
                      <p></p>
                      <span>{"Gym: ".toUpperCase()}</span>
                      <span>{characters.at(selected).textEng.gym}</span>
                      <p></p>
                    </div>
                  )}
                </Col>
              </Row>
              <Row style={{ color: "white" }}>
                <div style={{ zIndex: 3 }}>
                  {" "}
                  {characters.map((item, index) => (
                    <img
                      src={item.thumb}
                      alt=""
                      width="16%"
                      style={{
                        border: index == selected ? "red solid 0.2em" : "none",
                      }}
                      onClick={() => setSelected(index)}
                      id={index}
                    />
                  ))}
                  <Lottie
                    animationData={graficaBottom}
                    style={{ width: "25em " }}
                  />
                </div>

                <img
                  src={characters.at(selected).big}
                  alt=""
                  height="120%"
                  style={{
                    position: "absolute",
                    top: "0%",
                    right: "-20%",
                    zIndex: 1,
                  }}
                />
              </Row>
            </Col>
          </Row>
        </div>
      )}
      <div style={{ width: "0", height: "0", opacity: "0" }}>
        <img src={Santiago} style={{ width: "0", height: "0", opacity: "0" }} />
        <img
          src={SantiagoBg}
          style={{ width: "0", height: "0", opacity: "0" }}
        />
        <img src={Topo} style={{ width: "0", height: "0", opacity: "0" }} />
        <img src={TopoBg} style={{ width: "0", height: "0", opacity: "0" }} />
        <img src={Hannah} style={{ width: "0", height: "0", opacity: "0" }} />
        <img src={HannahBg} style={{ width: "0", height: "0", opacity: "0" }} />
        <img src={Anton} style={{ width: "0", height: "0", opacity: "0" }} />
        <img src={AntonBg} style={{ width: "0", height: "0", opacity: "0" }} />
        <img src={Artemisa} style={{ width: "0", height: "0", opacity: "0" }} />
        <img
          src={ArtemisaBg}
          style={{ width: "0", height: "0", opacity: "0" }}
        />
        <img src={Lorein} style={{ width: "0", height: "0", opacity: "0" }} />
        <img src={LoreinBg} style={{ width: "0", height: "0", opacity: "0" }} />
        <img src={Logan} style={{ width: "0", height: "0", opacity: "0" }} />
        <img src={LoganBg} style={{ width: "0", height: "0", opacity: "0" }} />
        <img src={Ernest} style={{ width: "0", height: "0", opacity: "0" }} />
        <img src={ErnestBg} style={{ width: "0", height: "0", opacity: "0" }} />
        {characters.forEach((element, index) => (
          <Lottie
            animationData={characters.at(index).side}
            loop={false}
            style={{ width: "0", height: "0", opacity: "0" }}
          />
        ))}
        {characters.forEach((element, index) => (
          <Lottie
            animationData={characters.at(index).stats}
            loop={false}
            style={{ width: "0", height: "0", opacity: "0" }}
          />
        ))}
        <Lottie
          animationData={loadingAnim}
          style={{ width: "0", height: "0", opacity: "0" }}
        />
        <Lottie
          animationData={mobileTitleAnton}
          style={{ width: "0", height: "0", opacity: "0" }}
        />{" "}
        <Lottie
          animationData={mobileTitleArtemisa}
          style={{ width: "0", height: "0", opacity: "0" }}
        />
        <Lottie
          animationData={mobileTitleErnest}
          style={{ width: "0", height: "0", opacity: "0" }}
        />
        <Lottie
          animationData={mobileTitleHannah}
          style={{ width: "0", height: "0", opacity: "0" }}
        />
        <Lottie
          animationData={mobileTitleLogan}
          style={{ width: "0", height: "0", opacity: "0" }}
        />
        <Lottie
          animationData={mobileTitleLorrein}
          style={{ width: "0", height: "0", opacity: "0" }}
        />
        <Lottie
          animationData={mobileTitleSantiago}
          style={{ width: "0", height: "0", opacity: "0" }}
        />
        <Lottie
          animationData={mobileTitleTopo}
          style={{ width: "0", height: "0", opacity: "0" }}
        />
        <Lottie
          animationData={antonNick}
          style={{ width: "0", height: "0", opacity: "0" }}
        />{" "}
        <Lottie
          animationData={artemisaNick}
          style={{ width: "0", height: "0", opacity: "0" }}
        />
        <Lottie
          animationData={ernestNick}
          style={{ width: "0", height: "0", opacity: "0" }}
        />
        <Lottie
          animationData={hannahNick}
          style={{ width: "0", height: "0", opacity: "0" }}
        />
        <Lottie
          animationData={loagnNick}
          style={{ width: "0", height: "0", opacity: "0" }}
        />
        <Lottie
          animationData={loreinNick}
          style={{ width: "0", height: "0", opacity: "0" }}
        />
        <Lottie
          animationData={santiagoNick}
          style={{ width: "0", height: "0", opacity: "0" }}
        />
        <Lottie
          animationData={topoNick}
          style={{ width: "0", height: "0", opacity: "0" }}
        />
      </div>
    </>
  );
};

export default Characters;
