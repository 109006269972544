import { useState, useEffect, useRef } from 'react'
import { Row, Col } from 'antd'
import { Link } from 'react-router-dom'
import './information.css'
import fondoCiudad from '../imgs/FondoCiudad-01.svg'
import arrow from '../imgs/arrow.svg'
import arrow2 from '../imgs/arrow2.svg'
import logoSide from '../anims/LogoSide.json'
import topoEng from '../anims/topoEngv2.json'
import topoEsp from '../anims/topoEspv2.json'

import loadingAnim from '../anims/Loading.json'

import Lottie from 'lottie-react'

const Information = ({ setNavbarMode, lang }) => {
  const [device, setDevice] = useState('')

  useEffect(() => {
    setNavbarMode('black')
    checkWidth()
  }, [])

  function checkWidth() {
    if (window.outerWidth > 1000) {
      setDevice('pc')
    } else if (window.outerWidth > 425 && window.outerWidth < 1000) {
      setDevice('tablet')
    } else if (window.outerWidth <= 425) {
      setDevice('phone')
    }
  }

  window.addEventListener('resize', function () {
    checkWidth()
  })

  window.addEventListener('orientationchange', function () {
    checkWidth()
  })

  const [loading, setLoading] = useState(true)
  const [imagesLoaded, setImagesLoaded] = useState(0)
  const totalImages = useRef(0)

  useEffect(() => {
    const images = Array.from(document.querySelectorAll('img'))
    totalImages.current = images.length
    console.log(images)
    const handleImageLoad = () => {
      setImagesLoaded((prevImagesLoaded) => prevImagesLoaded + 1)
    }

    images.forEach((image) => {
      if (image.complete) {
        handleImageLoad()
      } else {
        image.addEventListener('load', handleImageLoad)
      }
    })

    return () => {
      images.forEach((image) => {
        image.removeEventListener('load', handleImageLoad)
      })
    }
  }, [])

  useEffect(() => {
    if (imagesLoaded === totalImages.current) {
      setLoading(false)
    }
  }, [imagesLoaded])

  return (
    <>
      {loading ? (
        <div className="loading-screen">
          <Lottie animationData={loadingAnim} className="loading" />
        </div>
      ) : device == 'pc' ? (
        <div
          style={{ display: 'flex', height: '100%', justifyContent: 'center' }}
        >
          <Link to={'/information'}>
            <div
              style={{
                position: 'absolute',
                top: '50%',
                left: '5%',
                padding: '1em',
                zIndex: '10',
              }}
            >
              <img src={arrow2} alt="" width={'20em'} id="img" />
            </div>
          </Link>
          <Row
            justify="space-between"
            align="bottom"
            wrap={false}
            style={{ height: '90vh' }}
          >
            <Col span={4}>
              <div
                style={{
                  backgroundColor: 'black',
                  height: '90vh',
                  overflow: 'hidden',
                }}
              >
                <img src={fondoCiudad} alt="" height="100%" id="img" />
              </div>
            </Col>
            <Col>
              <div
                style={{
                  backgroundColor: 'black',
                  height: '90vh',
                }}
              >
                {lang == 'esp' ? (
                  <Lottie
                    animationData={topoEsp}
                    loop={false}
                    style={{ height: '100%' }}
                  />
                ) : (
                  <Lottie
                    animationData={topoEng}
                    loop={false}
                    style={{ height: '100%' }}
                  />
                )}
              </div>
            </Col>
            <Col>
              <div
                style={{
                  backgroundColor: 'black',
                  width: '20vw',
                  height: '90vh',
                  overflow: 'hidden',
                }}
              >
                <Lottie
                  animationData={logoSide}
                  style={{ overflow: 'hidden' }}
                  id="img"
                />
              </div>
            </Col>
          </Row>
          <Link to={'/more-information'}>
            <div
              style={{
                position: 'absolute',
                top: '50%',
                right: '5%',
                padding: '1em',
                zIndex: '10',
              }}
            >
              <img src={arrow} alt="" width={'20em'} id="img" />
            </div>
          </Link>
        </div>
      ) : device == 'phone' ? (
        <div style={{ display: 'flex', height: '100%' }}>
          <Link to={'/information'}>
            <div
              style={{
                position: 'absolute',
                top: '50%',
                left: '5%',
                padding: '1em',
                zIndex: '10',
              }}
            >
              <img src={arrow2} alt="" width={'20em'} id="img" />
            </div>
          </Link>

          <div
            style={{
              backgroundColor: 'black',
              position: 'absolute',
              top: '30%',
              left: '0',
              zIndex: '1',
            }}
          >
            {lang == 'esp' ? (
              <Lottie animationData={topoEsp} loop={false} />
            ) : (
              <Lottie animationData={topoEng} loop={false} />
            )}
          </div>

          <Link to={'/more-information'}>
            <div
              style={{
                position: 'absolute',
                top: '50%',
                right: '5%',
                padding: '1em',
                zIndex: '10',
              }}
            >
              <img src={arrow} alt="" width={'20em'} id="img" />
            </div>
          </Link>
        </div>
      ) : (
        <div style={{ display: 'flex', height: '100%' }}>
          <Link to={'/information'}>
            <div
              style={{
                position: 'absolute',
                top: '50%',
                left: '5%',
                padding: '1em',
                zIndex: '10',
              }}
            >
              <img src={arrow2} alt="" width={'20em'} id="img" />
            </div>
          </Link>

          <div
            style={{
              backgroundColor: 'black',
              position: 'absolute',
              top: '20%',
              left: '0',
              zIndex: '1',
            }}
          >
            {lang == 'esp' ? (
              <Lottie animationData={topoEsp} loop={false} />
            ) : (
              <Lottie animationData={topoEng} loop={false} />
            )}
          </div>

          <Link to={'/more-information'}>
            <div
              style={{
                position: 'absolute',
                top: '50%',
                right: '5%',
                padding: '1em',
                zIndex: '10',
              }}
            >
              <img src={arrow} alt="" width={'20em'} id="img" />
            </div>
          </Link>
        </div>
      )}

      <div style={{ position: 'absolute' }}>
        <Lottie
          animationData={loadingAnim}
          style={{ width: '0', height: '0', opacity: '0' }}
        />
        <img src={arrow} style={{ width: '0', height: '0', opacity: '0' }} />
        <img
          src={fondoCiudad}
          style={{ width: '0', height: '0', opacity: '0' }}
        />
        <img src={arrow2} style={{ width: '0', height: '0', opacity: '0' }} />
        <Lottie
          animationData={topoEsp}
          style={{ width: '0', height: '0', opacity: '0' }}
        />
        <Lottie
          animationData={topoEng}
          style={{ width: '0', height: '0', opacity: '0' }}
        />
        <Lottie
          animationData={logoSide}
          style={{ width: '0', height: '0', opacity: '0' }}
        />
      </div>
    </>
  )
}

export default Information
