import "./mainpage.css";
import Navbar from "./Navbar";
import { useState, useEffect } from "react";

const MainPage = ({ setNavbarMode, lang }) => {
  const [device, setDevice] = useState("");

  useEffect(() => {
    setNavbarMode("black");
    checkWidth();
  }, []);

  function checkWidth() {
    if (window.outerWidth > 1000) {
      setDevice("pc");
    } else if (window.outerWidth > 425 && window.outerWidth < 1000) {
      setDevice("tablet");
    } else if (window.outerWidth <= 425) {
      setDevice("phone");
    }
  }

  window.addEventListener("resize", function () {
    checkWidth();
  });

  window.addEventListener("orientationchange", function () {
    checkWidth();
  });

  return (
    <>
      {device == "pc" ? (
        <div className="main">
          <div className="video-container">
            <iframe
              src="https://www.youtube.com/embed/dnBRyz_yyaQ"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
              className="video"
            ></iframe>
          </div>
          {lang == "esp" ? (
            <a
              href="https://www.youtube.com/watch?v=jHF7TKCzTQY"
              style={{ textDecoration: "none" }}
            >
              <h1 className="link-class">Ver el Trailer Completo</h1>
            </a>
          ) : (
            <a
              href="https://www.youtube.com/watch?v=jHF7TKCzTQY"
              style={{ textDecoration: "none" }}
            >
              <h1 className="link-class">Watch the Full Trailer</h1>
            </a>
          )}
        </div>
      ) : device == "phone" ? (
        <div className="main">
          <div className="video-container">
            <iframe
              src="https://www.youtube.com/embed/dnBRyz_yyaQ"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
              className="video-phone"
            ></iframe>
          </div>
          {lang == "esp" ? (
            <a
              href="https://www.youtube.com/watch?v=jHF7TKCzTQY"
              style={{ textDecoration: "none" }}
            >
              <h3 className="link-class">Ver el Trailer Completo</h3>
            </a>
          ) : (
            <a
              href="https://www.youtube.com/watch?v=jHF7TKCzTQY"
              style={{ textDecoration: "none" }}
            >
              <h3 className="link-class">Watch the Full Trailer</h3>
            </a>
          )}
        </div>
      ) : (
        <div className="main">
          <div className="video-container">
            <iframe
              src="https://www.youtube.com/embed/dnBRyz_yyaQ"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
              className="video-tablet"
            ></iframe>
          </div>
          {lang == "esp" ? (
            <a
              href="https://www.youtube.com/watch?v=jHF7TKCzTQY"
              style={{ textDecoration: "none" }}
            >
              <h3 className="link-class">Ver el Trailer Completo</h3>
            </a>
          ) : (
            <a
              href="https://www.youtube.com/watch?v=jHF7TKCzTQY"
              style={{ textDecoration: "none" }}
            >
              <h3 className="link-class">Watch the Full Trailer</h3>
            </a>
          )}
        </div>
      )}
    </>
  );
};

export default MainPage;
