import { useState, useEffect, useRef } from 'react'
import { Row, Col } from 'antd'
import { Link } from 'react-router-dom'
import logoAnimado from '../imgs/LogoAnimado-01.svg'
import arrow from '../imgs/arrow.svg'
import arrow2 from '../imgs/arrow2.svg'
import psiques from '../imgs/PSIQUES-01.svg'
import grafica01 from '../imgs/Grafica01.svg'
import grafica02 from '../imgs/Grafica02.svg'
import hexagon from '../imgs/hexagon2.png'
import borderCorner from '../imgs/border-01.svg'
import iniLogo from '../imgs/Iniciadores-01.svg'
import conLogo from '../imgs/Constructor-01.svg'
import eleLogo from '../imgs/Elementales-01.svg'
import genLogo from '../imgs/Generador-01.svg'
import perLogo from '../imgs/Perfeccionistas-01.svg'
import velLogo from '../imgs/Velocistas-01.svg'
import logoSide from '../anims/LogoSide.json'
import Lottie from 'lottie-react'

import './moreinformation.css'

import loadingAnim from '../anims/Loading.json'

const MoreInformation = ({ setNavbarMode, lang }) => {
  const descriptions = [
    {
      title: 'Creadores',
      description:
        'Creators capaces de unificar su Psique a reliquias que pasan de generación en generación por miembros de la misma familia (Usuarios de armas Hered)',
      titleEng: 'Creators',
      descriptionEng:
        'Creators capable of unifying their Psyche to relics that are passed from generation to generation by members of the same family (Inherited Weapon Users)',
    },
    {
      title: 'Velocistas',
      description:
        'Únicos y pocos Creators que son capaces de utilizar la Fuerza C (Cinética). Por lo mismo, no todos sus portadores pueden llegar activarla al 100% (esto ocasiona que solo partes de su cuerpo sean afectados por esta)',
      titleEng: 'Sprinters',
      descriptionEng:
        'Only and few Creators who are capable of using Force C (Kinetic). For the same reason, not all its carriers can activate it 100% (this causes only parts of their body to be affected by it)',
    },
    {
      title: 'Constructores',
      description:
        'Creators que posean la habilidad de crear artefactos para adecuarse a su estilo de pelea (requieren tener conocimiento de este objeto o material real al 100%)',
      titleEng: 'Builders',
      descriptionEng:
        'Creators who possess the ability to create artifacts to suit their fighting style (requires 100% knowledge of this actual item or material)',
    },
    {
      title: 'Generadores',
      description:
        'Creators capaces de utilizar las Armas Shamar o Soul, estas funcionan como contenedores para los seres o espíritus que habitan en ellas y que permiten la conexión con el Psique del usuario.',
      titleEng: 'Generators',
      descriptionEng:
        'Creators capable of using Shamar or Soul Weapons, these function as containers for the beings or spirits that inhabit them and that allow the connection with the Psyche of the user.',
    },
    {
      title: 'Perfeccionistas',
      description:
        'Creators que posean un Psique único. Generalmente requieren de Armas Arquetic para mantener una estabilidad en su materialización.',
      titleEng: 'Perfectionists',
      descriptionEng:
        'Creators who possess a unique Psyche. They generally require Archetic Weapons to maintain stability in their materialization.',
    },
    {
      title: 'Elementos',
      description:
        'Creators capaces de manipular a través de su Psique. Uno de los Cuatro elementos básicos, agua, fuego, aire, tierra y sus derivados, Esto incluye los elementos químicos también.',
      titleEng: 'Elements',
      descriptionEng:
        'Creators capable of manipulating through their Psyche. One of the Four basic elements, water, fire, air, earth and their derivatives, This includes the chemical elements as well.',
    },
  ]
  const [description, setDescription] = useState(descriptions.at(0).description)
  const [title, setTitle] = useState(descriptions.at(0).title)

  const [device, setDevice] = useState('')

  function checkWidth() {
    if (window.outerWidth > 1000) {
      setDevice('pc')
      //setWheelScale(10 + this.window.innerWidth / 10 + '%')
    } else if (window.outerWidth > 425 && window.outerWidth < 1000) {
      setDevice('tablet')
      //setWheelScale(10 + this.window.innerHeight / 10 + '%')

    } else if (window.outerWidth <= 425) {
      setDevice('phone')
      //setWindowW(10 + this.window.innerHeight / 8 + '%')
      //setWheelScale(10 + this.window.innerHeight / 8 + '%')
    }
  }

  window.addEventListener('resize', function () {
    checkWidth()
  })

  window.addEventListener('orientationchange', function () {
    checkWidth()
  })

  useEffect(() => {
    setNavbarMode('white')
    checkWidth()
    if (lang == 'esp') {
      setDescription(descriptions.at(0).description)
    } else {
      setDescription(descriptions.at(0).descriptionEng)
    }
  }, [])

  const [loading, setLoading] = useState(true)
  const [imagesLoaded, setImagesLoaded] = useState(0)
  const totalImages = useRef(0)

  useEffect(() => {
    const images = Array.from(document.querySelectorAll('img'))
    totalImages.current = images.length
    console.log(images)
    const handleImageLoad = () => {
      setImagesLoaded((prevImagesLoaded) => prevImagesLoaded + 1)
    }

    images.forEach((image) => {
      if (image.complete) {
        handleImageLoad()
      } else {
        image.addEventListener('load', handleImageLoad)
      }
    })

    return () => {
      images.forEach((image) => {
        image.removeEventListener('load', handleImageLoad)
      })
    }
  }, [])

  useEffect(() => {
    if (imagesLoaded === totalImages.current) {
      setLoading(false)
    }
  }, [imagesLoaded])

  const [rotateClass, setRotateClass] = useState('')
  const [textBoxClass, setTextBoxClass] = useState('text-box-100')

  function setSelected(e, selected, number) {
    e.preventDefault()
    setRotateClass(selected)

    setTextBoxClass('text-box')
    setTimeout(() => {
      setTextOpacity(number)
    }, 1000)
  }
  function setTextOpacity(number) {
    setTextBoxClass('text-box-100')
    if (lang == 'esp') {
      setTitle(descriptions.at(number).title)
      setDescription(descriptions.at(number).description)
    } else {
      setTitle(descriptions.at(number).titleEng)
      setDescription(descriptions.at(number).descriptionEng)
    }
  }


  return (
    <>
      {loading ? (
        <div className="loading-screen">
          <Lottie animationData={loadingAnim} className="loading" />
        </div>
      ) : device == 'pc' ? (
        <div
          style={{ display: 'flex', height: '100%', backgroundColor: 'white' }}
        >
          <Link to={'/information'}>
            <div
              style={{
                position: 'absolute',
                top: '50%',
                left: '5%',
                padding: '1em',
                zIndex: '10',
              }}
            >
              <img src={arrow2} alt="" width={'20em'} id="img" />
            </div>
          </Link>

          <Row
            justify={'center'}
            wrap={false}
            style={{ height: '90vh', overflow: 'hidden', width: '100%' }}
          >
            <Col>
              <Lottie
                animationData={logoSide}
                loop={true}
                style={{ width: '20em', opacity: '20%' }}
                id="img"
              />
            </Col>
            <Col>
              <Row>
                <img
                  src={grafica01}
                  alt=""
                  width={'800em'}
                  style={{ paddingTop: '1em' }}
                  id="img"
                />
              </Row>
              <Row justify={'center'}>
                <div
                  style={{
                    position: 'absolute',
                    bottom: '35%',
                    scale: '100%',
                  }}
                >
                  <button
                    style={{
                      position: 'absolute',
                      right: '0em',
                      top: '-8em',
                      border: 'none',
                      background: 'none',
                    }}
                    className="selected-btn"
                    onClick={(e) => setSelected(e, 'ini', 0)}
                  >
                    <img src={iniLogo} alt="" width={'40%'} id="img" />

                    <h1>
                      {lang == 'esp'
                        ? descriptions.at(0).title.toUpperCase()
                        : descriptions.at(0).titleEng.toUpperCase()}
                    </h1>
                  </button>
                  <button
                    style={{
                      position: 'absolute',
                      right: '18em',
                      top: '-3em',
                      border: 'none',
                      background: 'none',
                    }}
                    className="selected-btn"
                    onClick={(e) => setSelected(e, 'vel', 1)}
                  >
                    {' '}
                    <img src={velLogo} alt="" width={'40%'} id="img" />
                    <h1>
                      {lang == 'esp'
                        ? descriptions.at(1).title.toUpperCase()
                        : descriptions.at(1).titleEng.toUpperCase()}
                    </h1>{' '}
                  </button>
                  <button
                    style={{
                      position: 'absolute',
                      right: '-20em',
                      top: '-5em',
                      border: 'none',
                      background: 'none',
                    }}
                    className="selected-btn"
                    onClick={(e) => setSelected(e, 'con', 2)}
                  >
                    <img src={conLogo} alt="" width={'30%'} id="img" />

                    <h1>
                      {lang == 'esp'
                        ? descriptions.at(2).title.toUpperCase()
                        : descriptions.at(2).titleEng.toUpperCase()}
                    </h1>
                  </button>
                  <button
                    style={{
                      position: 'absolute',
                      right: '-19em',
                      top: '5em',
                      border: 'none',
                      background: 'none',
                    }}
                    className="selected-btn"
                    onClick={(e) => setSelected(e, 'gen', 3)}
                  >
                    <img src={genLogo} alt="" width={'30%'} id="img" />

                    <h1>
                      {lang == 'esp'
                        ? descriptions.at(3).title.toUpperCase()
                        : descriptions.at(3).titleEng.toUpperCase()}
                    </h1>
                  </button>
                  <button
                    style={{
                      position: 'absolute',
                      right: '18em',
                      top: '6em',
                      border: 'none',
                      background: 'none',
                    }}
                    className="selected-btn"
                    onClick={(e) => setSelected(e, 'per', 4)}
                  >
                    <img src={perLogo} alt="" width={'30%'} id="img" />

                    <h1>
                      {lang == 'esp'
                        ? descriptions.at(4).title.toUpperCase()
                        : descriptions.at(4).titleEng.toUpperCase()}
                    </h1>
                  </button>
                  <button
                    style={{
                      position: 'absolute',
                      border: 'none',
                      background: 'none',
                      right: '0em',
                      top: '18em',
                      zIndex: 2,
                    }}
                    className="selected-btn"
                    onClick={(e) => setSelected(e, 'ele', 5)}
                  >
                    <h1>
                      {lang == 'esp'
                        ? descriptions.at(5).title.toUpperCase()
                        : descriptions.at(5).titleEng.toUpperCase()}
                    </h1>
                    <img src={eleLogo} alt="" width={'40%'} id="img" />
                  </button>
                  <img
                    src={hexagon}
                    alt=""
                    width={'210em'}
                    className={rotateClass}
                    style={{ padding: '1em' }}
                    id="img"
                  />
                </div>
              </Row>
              <Row>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    position: 'absolute',
                    bottom: '5%',
                  }}
                >
                  <div
                    style={{
                      position: 'relative',
                      left: '-10%',
                      padding: '1em',
                    }}
                  >
                    <div
                      style={{
                        position: 'absolute',
                        bottom: '15%',
                        left: '-80%',
                      }}
                    >
                      <img src={borderCorner} alt="" width="350em" id="img" />
                    </div>
                    <div
                      style={{
                        position: 'absolute',
                        bottom: '35%',
                        textAlign: 'center',
                        width: '20em',
                      }}
                    >
                      <h1 className={textBoxClass}>{title}</h1>
                      <p className={textBoxClass}>{description}</p>
                    </div>
                  </div>
                  <img
                    src={grafica02}
                    alt=""
                    width={'500em'}
                    style={{ position: 'relative', left: '50%' }}
                    id="img"
                  />
                </div>
              </Row>
            </Col>
          </Row>
          <Link to={'/more-information'}>
            <div
              style={{
                position: 'absolute',
                top: '50%',
                right: '5%',
                padding: '1em',
                zIndex: '10',
              }}
            >
              <img src={arrow} alt="" width={'20em'} id="img" />
            </div>
          </Link>
        </div>
      ) : device == 'phone' ? (
        <div
          style={{ display: 'flex', height: '100%', backgroundColor: 'white' }}
        >
          <Link to={'/information'}>
            <div
              style={{
                position: 'absolute',
                top: '50%',
                left: '5%',
                padding: '1em',
                zIndex: '10',
              }}
            >
              <img src={arrow2} alt="" width={'20em'} id="img" />
            </div>
          </Link>

          <Row
            justify={'center'}
            wrap={false}
            style={{ height: '90vh', overflow: 'hidden', width: '100%' }}
          >
            <Col>
              <Row>
                <img
                  src={grafica01}
                  alt=""
                  width={'600em'}
                  style={{ paddingTop: '1em' }}
                  id="img"
                />
              </Row>
              <Row justify={'center'}>
                <div
                  style={{
                    position: 'absolute',
                    bottom: '50%',
                    scale: '45%',
                  }}
                >
                  <button
                    style={{
                      position: 'absolute',
                      right: '0em',
                      top: '-8em',
                      border: 'none',
                      background: 'none',
                    }}
                    className="selected-btn"
                    onClick={(e) => setSelected(e, 'ini', 0)}
                  >
                    <img src={iniLogo} alt="" width={'40%'} id="img" />

                    <h1>
                      {lang == 'esp'
                        ? descriptions.at(0).title.toUpperCase()
                        : descriptions.at(0).titleEng.toUpperCase()}
                    </h1>
                  </button>
                  <button
                    style={{
                      position: 'absolute',
                      right: '18em',
                      top: '-3em',
                      border: 'none',
                      background: 'none',
                    }}
                    className="selected-btn"
                    onClick={(e) => setSelected(e, 'vel', 1)}
                  >
                    {' '}
                    <img src={velLogo} alt="" width={'40%'} id="img" />
                    <h1>
                      {lang == 'esp'
                        ? descriptions.at(1).title.toUpperCase()
                        : descriptions.at(1).titleEng.toUpperCase()}
                    </h1>{' '}
                  </button>
                  <button
                    style={{
                      position: 'absolute',
                      right: '-20em',
                      top: '-5em',
                      border: 'none',
                      background: 'none',
                    }}
                    className="selected-btn"
                    onClick={(e) => setSelected(e, 'con', 2)}
                  >
                    <img src={conLogo} alt="" width={'30%'} id="img" />

                    <h1>
                      {lang == 'esp'
                        ? descriptions.at(2).title.toUpperCase()
                        : descriptions.at(2).titleEng.toUpperCase()}
                    </h1>
                  </button>
                  <button
                    style={{
                      position: 'absolute',
                      right: '-19em',
                      top: '5em',
                      border: 'none',
                      background: 'none',
                    }}
                    className="selected-btn"
                    onClick={(e) => setSelected(e, 'gen', 3)}
                  >
                    <img src={genLogo} alt="" width={'30%'} id="img" />

                    <h1>
                      {lang == 'esp'
                        ? descriptions.at(3).title.toUpperCase()
                        : descriptions.at(3).titleEng.toUpperCase()}
                    </h1>
                  </button>
                  <button
                    style={{
                      position: 'absolute',
                      right: '18em',
                      top: '6em',
                      border: 'none',
                      background: 'none',
                    }}
                    className="selected-btn"
                    onClick={(e) => setSelected(e, 'per', 4)}
                  >
                    <img src={perLogo} alt="" width={'30%'} id="img" />

                    <h1>
                      {lang == 'esp'
                        ? descriptions.at(4).title.toUpperCase()
                        : descriptions.at(4).titleEng.toUpperCase()}
                    </h1>
                  </button>
                  <button
                    style={{
                      position: 'absolute',
                      border: 'none',
                      background: 'none',
                      right: '0em',
                      top: '18em',
                      zIndex: 2,
                    }}
                    className="selected-btn"
                    onClick={(e) => setSelected(e, 'ele', 5)}
                  >
                    <h1>
                      {lang == 'esp'
                        ? descriptions.at(5).title.toUpperCase()
                        : descriptions.at(5).titleEng.toUpperCase()}
                    </h1>
                    <img src={eleLogo} alt="" width={'40%'} id="img" />
                  </button>
                  <img
                    src={hexagon}
                    alt=""
                    width={'210em'}
                    className={rotateClass}
                    style={{ padding: '1em' }}
                    id="img"
                  />
                </div>
                <div style={{ position: 'absolute', top: '50%' }}>
                  <img src={borderCorner} alt="" width="300em" id="img" />
                </div>
                <div
                  style={{
                    position: 'absolute',
                    top: '46%',
                    width: '18em',
                    textAlign: 'center',
                  }}
                >
                  <h1 className={textBoxClass}>{title}</h1>
                  <p className={textBoxClass}>{description}</p>
                </div>
              </Row>
              <Row>
                <div style={{ position: 'absolute', top: '70%' }}>
                  <img
                    src={grafica02}
                    alt=""
                    width={'420em'}
                    style={{ paddingTop: '1em' }}
                    id="img"
                  />
                </div>
              </Row>
            </Col>
          </Row>
          <Link to={'/more-information'}>
            <div
              style={{
                position: 'absolute',
                top: '50%',
                right: '5%',
                padding: '1em',
                zIndex: '10',
              }}
            >
              <img src={arrow} alt="" width={'20em'} id="img" />
            </div>
          </Link>
        </div>
      ) : (
        <div
          style={{ display: 'flex', height: '100%', backgroundColor: 'white' }}
        >
          <Link to={'/information'}>
            <div
              style={{
                position: 'absolute',
                top: '50%',
                left: '5%',
                padding: '1em',
                zIndex: '10',
              }}
            >
              <img src={arrow2} alt="" width={'20em'} id="img" />
            </div>
          </Link>

          <Row
            justify={'center'}
            wrap={false}
            style={{ height: '90vh', overflow: 'hidden', width: '100%' }}
          >
            <Col>
              <Row>
                <img
                  src={grafica01}
                  alt=""
                  width={'600em'}
                  style={{ paddingTop: '1em' }}
                  id="img"
                />
              </Row>
              <Row justify={'center'}>
                <div
                  style={{
                    position: 'absolute',
                    bottom: '50%',
                    scale: '100%',
                  }}
                >
                  <button
                    style={{
                      position: 'absolute',
                      right: '0em',
                      top: '-6em',
                      border: 'none',
                      background: 'none',
                    }}
                    className="selected-btn-mobile"
                    onClick={(e) => setSelected(e, 'ini', 0)}
                  >
                    <img src={iniLogo} alt="" width={'40%'} id="img" />

                    <h1>
                      {lang == 'esp'
                        ? descriptions.at(0).title.toUpperCase()
                        : descriptions.at(0).titleEng.toUpperCase()}
                    </h1>
                  </button>
                  <button
                    style={{
                      position: 'absolute',
                      right: '18em',
                      top: '-2em',
                      border: 'none',
                      background: 'none',
                    }}
                    className="selected-btn-mobile"
                    onClick={(e) => setSelected(e, 'vel', 1)}
                  >
                    {' '}
                    <img src={velLogo} alt="" width={'40%'} id="img" />
                    <h1>
                      {lang == 'esp'
                        ? descriptions.at(1).title.toUpperCase()
                        : descriptions.at(1).titleEng.toUpperCase()}
                    </h1>{' '}
                  </button>
                  <button
                    style={{
                      position: 'absolute',
                      right: '-20em',
                      top: '-2em',
                      border: 'none',
                      background: 'none',
                    }}
                    className="selected-btn-mobile"
                    onClick={(e) => setSelected(e, 'con', 2)}
                  >
                    <img src={conLogo} alt="" width={'30%'} id="img" />

                    <h1>
                      {lang == 'esp'
                        ? descriptions.at(2).title.toUpperCase()
                        : descriptions.at(2).titleEng.toUpperCase()}
                    </h1>
                  </button>
                  <button
                    style={{
                      position: 'absolute',
                      right: '-19em',
                      top: '7em',
                      border: 'none',
                      background: 'none',
                    }}
                    className="selected-btn-mobile"
                    onClick={(e) => setSelected(e, 'gen', 3)}
                  >
                    <img src={genLogo} alt="" width={'30%'} id="img" />

                    <h1>
                      {lang == 'esp'
                        ? descriptions.at(3).title.toUpperCase()
                        : descriptions.at(3).titleEng.toUpperCase()}
                    </h1>
                  </button>
                  <button
                    style={{
                      position: 'absolute',
                      right: '16em',
                      top: '8em',
                      border: 'none',
                      background: 'none',
                    }}
                    className="selected-btn-mobile"
                    onClick={(e) => setSelected(e, 'per', 4)}
                  >
                    <img src={perLogo} alt="" width={'30%'} id="img" />

                    <h1>
                      {lang == 'esp'
                        ? descriptions.at(4).title.toUpperCase()
                        : descriptions.at(4).titleEng.toUpperCase()}
                    </h1>
                  </button>
                  <button
                    style={{
                      position: 'absolute',
                      border: 'none',
                      background: 'none',
                      right: '0em',
                      top: '18em',
                      zIndex: 2,
                    }}
                    className="selected-btn-mobile"
                    onClick={(e) => setSelected(e, 'ele', 5)}
                  >
                    <h1>
                      {lang == 'esp'
                        ? descriptions.at(5).title.toUpperCase()
                        : descriptions.at(5).titleEng.toUpperCase()}
                    </h1>
                    <img src={eleLogo} alt="" width={'40%'} id="img" />
                  </button>
                  <img
                    src={hexagon}
                    alt=""
                    width={'210em'}
                    className={rotateClass}
                    style={{ padding: '1em' }}
                    id="img"
                  />
                </div>
              </Row>
              <Row justify={'center'}>
                <div style={{ position: 'absolute', bottom: '10%' }}>
                  <img src={borderCorner} alt="" width="400em" id="img" />
                </div>
                <div
                  style={{
                    position: 'absolute',
                    bottom: '13%',
                    width: '25em',
                    textAlign: 'center',
                  }}
                >
                  <h1 className={textBoxClass} style={{ fontSize: '2em' }}>
                    {title}
                  </h1>
                  <p className={textBoxClass} style={{ fontSize: '1.2em' }}>
                    {description}
                  </p>
                </div>
              </Row>
              <Row>
                <div style={{ position: 'absolute', bottom: '5%' }}>
                  <img
                    src={grafica02}
                    alt=""
                    width={'600em'}
                    style={{ paddingTop: '1em' }}
                    id="img"
                  />
                </div>
              </Row>
            </Col>
          </Row>
          <Link to={'/more-information'}>
            <div
              style={{
                position: 'absolute',
                top: '50%',
                right: '5%',
                padding: '1em',
                zIndex: '10',
              }}
            >
              <img src={arrow} alt="" width={'20em'} id="img" />
            </div>
          </Link>
        </div>
      )}
      <div style={{ position: 'absolute' }}>
        <img src={arrow2} style={{ width: '0', height: '0', opacity: '0' }} />
        <Lottie
          animationData={logoSide}
          style={{ width: '0', height: '0', opacity: '0' }}
        />
        <img
          src={grafica01}
          style={{ width: '0', height: '0', opacity: '0' }}
        />
        <img src={iniLogo} style={{ width: '0', height: '0', opacity: '0' }} />
        <img src={velLogo} style={{ width: '0', height: '0', opacity: '0' }} />
        <img src={conLogo} style={{ width: '0', height: '0', opacity: '0' }} />
        <img src={genLogo} style={{ width: '0', height: '0', opacity: '0' }} />
        <img src={perLogo} style={{ width: '0', height: '0', opacity: '0' }} />
        <img src={eleLogo} style={{ width: '0', height: '0', opacity: '0' }} />
        <img src={hexagon} style={{ width: '0', height: '0', opacity: '0' }} />
        <img
          src={borderCorner}
          style={{ width: '0', height: '0', opacity: '0' }}
        />
        <img
          src={grafica02}
          style={{ width: '0', height: '0', opacity: '0' }}
        />
        <img src={arrow} style={{ width: '0', height: '0', opacity: '0' }} />
      </div>
    </>
  )
}

export default MoreInformation
